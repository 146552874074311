export default [
  {
    id: 1,
    alias: "music",
    title: "Branding: Music Speaks Logo",
    descr:
      "The Barbarian is a kilt-clad Scottish warrior with an angry, battle-ready expression, hungry for destruction. He has Killer yellow horseshoe mustache.",
    img: "https://tocode.ru/static/uploads/courses/3/clash-of-clans-images/barbarian.png",
    lvl: 4,
    info: [
      { title: "training", value: "20s" },
      { title: "speed", value: "16" },
      { title: "cost", value: "150" },
    ],
  },
  {
    id: 2,
    alias: "archer",
    title: "The Archer",
    descr:
      "The Archer is a female warrior with sharp eyes. She wears a short, light green dress, a hooded cape, a leather belt and an attached small pouch.",
    img: "https://tocode.ru/static/uploads/courses/3/clash-of-clans-images/archer.png",
    lvl: 4,
    info: [
      { title: "training", value: "25s" },
      { title: "speed", value: "24" },
      { title: "cost", value: "300" },
    ],
  },
  {
    id: 3,
    alias: "giant",
    title: "The Giant",
    descr:
      "Slow, steady and powerful, Giants are massive warriors that soak up huge amounts of damage. Show them a turret or cannon and you will see their fury unleashed!",
    img: "https://tocode.ru/static/uploads/courses/3/clash-of-clans-images/giant.png",
    lvl: 4,
    info: [
      { title: "training", value: "2m" },
      { title: "speed", value: "12" },
      { title: "cost", value: "2250" },
    ],
  },
  {
    id: 4,
    alias: "goblin",
    title: "The Goblin",
    descr:
      "These pesky little creatures only have eyes for one thing: LOOT! They are faster than a Spring Trap, and their hunger for resources is limitless.",
    img: "https://tocode.ru/static/uploads/courses/3/clash-of-clans-images/goblin.png",
    lvl: 5,
    info: [
      { title: "training", value: "30s" },
      { title: "speed", value: "32" },
      { title: "cost", value: "100" },
    ],
  },
  {
    id: 5,
    alias: "wizard",
    title: "The Wizard",
    descr:
      "The Wizard is a terrifying presence on the battlefield. Pair him up with some of his fellows and cast concentrated blasts of destruction on anything, land or sky!",
    img: "https://tocode.ru/static/uploads/courses/3/clash-of-clans-images/wizard.png",
    lvl: 6,
    info: [
      { title: "training", value: "5m" },
      { title: "speed", value: "16" },
      { title: "cost", value: "3800" },
    ],
  },
];
